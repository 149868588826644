function dispatchEvent() {
	document.dispatchEvent(new Event("nettno:update-darkmode"));
}

let eventsAdded = false;
function addEvents(toggleLight, toggleDark, htmlElem) {
	eventsAdded = true;

	toggleLight.addEventListener('click', function () {
		window.localStorage.setItem('theme', 'light');
		htmlElem.classList.remove('dark');
		toggleDark.classList.add('visible');
		toggleDark.classList.remove('hidden');
		toggleLight.classList.add('hidden');
		toggleLight.classList.remove('visible');
		dispatchEvent();
	});

	toggleDark.addEventListener('click', function () {
		localStorage.setItem('theme', 'dark');
		htmlElem.classList.add('dark');
		toggleLight.classList.add('visible');
		toggleLight.classList.remove('hidden');
		toggleDark.classList.add('hidden');
		toggleDark.classList.remove('visible');
		dispatchEvent();
	});
}

export function isDarkMode() {
	const theme = window.localStorage.getItem('theme');
	const userPrefersDark = window.matchMedia('(prefers-color-scheme: dark)');

	return theme === 'dark' || (!theme && userPrefersDark.matches);
};

export function toggleDarkMode() {
	const toggleDark = document.getElementById('toggle-dark');
	const toggleLight = document.getElementById('toggle-light');
	const htmlElem = document.querySelector('html');

	if (isDarkMode()) {
		htmlElem.classList.add('dark');
		toggleDark.classList.add('visible');
		toggleLight.classList.remove('hidden');
	} else {
		toggleLight.classList.add('visible');
		toggleDark.classList.remove('hidden');
	}

	if(!eventsAdded) {
		addEvents(toggleLight, toggleDark, htmlElem);
	}
};
