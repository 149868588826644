import domReady from '../../plugins/domready.js'

domReady(() => {
	if (document.getElementById('social') != null) {
		let _ = import(
			/* webpackChunkName: "nettno-social" */
			/* webpackMode: "lazy" */

			"./social.js"
		);
	}
});
