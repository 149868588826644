import domReady from '../../plugins/domready.js'

domReady(() => {
	if (document.querySelectorAll('.search-view').length > 0) {
		let _ = import(
			/* webpackChunkName: "nettno-search-view" */
			/* webpackMode: "lazy" */

			"./search.js"
		);
	}
});
