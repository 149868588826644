import { isDarkMode } from "../../plugins/darkmode";

async function getHandlerInstance(el) {
	return new Promise(( resolve, reject ) => {
		setTimeout(() => {
			reject();
		}, 40000);

		const wait = async () => {
			if(el.handlerInstance?.elements) {
				return resolve(el.handlerInstance);
			}

			setTimeout(wait, 50);
		}

		setTimeout(wait, 0);
	});
}

function updateTheme(handlerInstance) {
	handlerInstance?.elements?.update({
		appearance: {
			theme: isDarkMode() ? 'night' : 'stripe'
		}
	});
}

function updateElementTheme(el) {
	updateTheme(el?.handlerInstance);
}

function stripeInit() {
	const forms = document.querySelectorAll('.payment-source-form');

	forms.forEach(async el => {
		const paymentForm = el.querySelector('.stripe-payment-form');
		const submitBtn = el.querySelector('.stripe-payment-elements-submit-button');

		// Hack since there is no cancel button available in craft stripe
		if(submitBtn) {
			const cancelBtn = el.querySelector('.btn-payment-source-cancel');

			const wrapper = document.createElement('div');
			wrapper.classList.add('flex', 'flex-col', 'md:flex-row', 'gap-4');

			wrapper.appendChild(submitBtn);
			wrapper.appendChild(cancelBtn);

			paymentForm.appendChild(wrapper);

			cancelBtn.classList.add('md:ml-auto');
			cancelBtn.classList.remove('hidden');
		}

		const stripeElement = el.querySelector('.stripe-payment-elements-form');
		const handlerInstance = await getHandlerInstance(stripeElement);
		updateTheme(handlerInstance);
	});

	const stripeElements = document.querySelectorAll('.stripe-payment-elements-form');

	stripeElements.forEach(async el => {
		const handlerInstance = await getHandlerInstance(el);
		updateTheme(handlerInstance);
	});
}

stripeInit();

document.addEventListener('nettno:update-darkmode', () => {
	const stripeElements = document.querySelectorAll('.stripe-payment-elements-form');
	stripeElements.forEach(el => {
		updateElementTheme(el);
	});
});
