import domReady from '../../../plugins/domready.js'

domReady(() => {
	if (document.getElementById('nettno-account-urls') != null) {
		let _ = import(
			/* webpackChunkName: "nettno-account-urls" */
			/* webpackMode: "lazy" */

			"./urls.js"
		);
	}
});

