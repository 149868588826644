import Cookies from 'js-cookie';

const cookieName = 'last-visited';
const cookieOptions = { secure: true, path: '/', expires: 1 };

export function getLastVisited() {
	return Cookies.get(cookieName) || null;
}

export function setLastVisited(entryId) {
	if(Number.isInteger(entryId)) {
		Cookies.set(cookieName, entryId, cookieOptions);
	}
}

export function removeLastVisited() {
	Cookies.remove(cookieName, cookieOptions);
}
