import domReady from '../../plugins/domready.js'

domReady(() => {
	if (document.querySelectorAll('.nettno-ads').length > 0) {
		let _ = import(
			/* webpackChunkName: "nettno-ads" */
			/* webpackMode: "lazy" */

			"./ad.js"
		);
	}
});
