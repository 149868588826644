import domReady from '../../plugins/domready.js'

domReady(() => {
	if (document.getElementById('nettno-order') != null) {
		let _ = import(
			/* webpackChunkName: "nettno-order" */
			/* webpackMode: "lazy" */

			"./order.js"
		);
	}
});

