import '../css/main.css'

import 'alpinejs'

import {
	$,
	jQuery
} from 'jquery'

// export for others scripts to use
window.$ = $;
window.jQuery = jQuery;

import { setLastVisited } from './plugins/last-visited';
window.setLastVisitedCookie = setLastVisited;

import './modules/stripe'
import './modules/plyr'
import './modules/reframe'
import './modules/lazysizes'

import './vue/account'
import './vue/order'
import './vue/search'
import './vue/ad'
import './vue/brandstories'
import './vue/jobs'
import './vue/social'

// PWA notification support
// Disabled for now, due to apple removing support for pwa
// Although they enabled it two weeks later again,
// it's best to just wait until it's 100% sure they aren't going to disable it again
// import './modules/pushmessage'
// import './vue/popups'

import domReady from './plugins/domready'
import { toggleDarkMode } from './plugins/darkmode'
import { incrementPageVisits } from './modules/pagevisits'

domReady(() => {
	toggleDarkMode();
	incrementPageVisits();
});
