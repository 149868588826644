import domReady from '../../plugins/domready.js'

domReady(() => {
	if (document.querySelectorAll('.homepage-jobs').length > 0) {
		let _ = import(
			/* webpackChunkName: "nettno-homepage-jobs" */
			/* webpackMode: "lazy" */

			"./jobs.js"
		);
	}
});
